// NavBar.js
import React, { useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  Badge,
  Container
} from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import MenuIcon from '@mui/icons-material/Menu';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import ForumIcon from '@mui/icons-material/Forum';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';

import socket from 'socketio/socket';
import userService from '../../api/services/userService';
import chatService from 'api/services/chatService';

import { 
  setChatSessionId,
  setFriendId,
  setFriendUserInfo,
  setSelectedCategoryId,
  setUnreadCount
} from 'app/slices/chat.slice';
import { 
  updateUser 
} from 'app/slices/user.slice';
import { setGroupChatSessionId } from 'app/slices/groupchat.slice';
import { Send } from '@mui/icons-material';

export default function NavBar() {
  const user = useSelector(state => state.user);
  const unreadCount = useSelector(state => state.chats.unreadCount);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  
  // User menu options
  const options = [ 
    { icon: <PersonRoundedIcon fontSize='inherit'/>, text: 'Edit Profile', link: "/profile", role: "user", type: 'link' },
    { icon: <PeopleAltRoundedIcon fontSize='inherit'/>, text: 'Referral', link: "/referral", role: "user", type: 'link' },
    { icon: <SettingsRoundedIcon fontSize='inherit'/>, text: 'Settings', link: "/settings", role: "user", type: 'link' },
    { icon: <LogoutRoundedIcon fontSize='inherit'/>, text: 'Logout', onClick: handleLogout, role: "user", type: 'button' }
  ];

  // Define navigation pages
  const pages = [ 
    { text: 'M8s', link: "/chats", role: "user", type: 'link'  },
    { text: 'Austin', link: "/events", role: "user", type: 'link' },
    //{ text: 'Penn State', link: "/psu", role: "user", type: 'link' },
    { text: 'Search', link: "/search", role: "user", type: 'link' },
    { text: 'YM8', link: "/YM8", role: "user", type: 'link'  },
    { text: 'The Difference', link: "/Difference", role: "user", type: 'link'  },
    { text: 'No H8', link: "/NoH8", role: "user", type: 'link'  },
    { text: 'Admin', link: "/admin", role: "admin", type: 'link'  },
  ];

  // Fetch total user count
  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const res = await userService.getUsercount();
        setUserCount(` Total M8s: ${res.count}`);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserCount();
  }, []);

  const [userCount, setUserCount] = useState("0");

  // Logout handler
  async function handleLogout(event) {
    event.preventDefault();
    try {
      const response = await userService.logout();
      if (response === "OK") {
        dispatch(updateUser(''));
        dispatch(setSelectedCategoryId(null));
        dispatch(setChatSessionId(null));
        dispatch(setGroupChatSessionId(null));
        dispatch(setFriendId(null));
        dispatch(setFriendUserInfo({}));
        socket.disconnect();
        navigate("/login");
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Navigation menu handlers
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handlePageClick = () => {
    handleCloseNavMenu();
  };

  // User menu handlers
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // Fetch total unread message count (regular + group)
  const getUnreadCount = async () => {
    try {
      const response = await chatService.getTotalUnreadCount(user.userId);
      dispatch(setUnreadCount(response.data.unreadCounter));
    } catch (error) {
      console.error(error);
    }
  };

  // Initialize unread count and set up socket listeners
  useEffect(() => {
    getUnreadCount();

    const handleUnreadUpdate = () => {
      getUnreadCount();
    };

    // Listen to both regular and group chat events
    socket.on('newChat', handleUnreadUpdate);
    socket.on('receiveMessage', handleUnreadUpdate);
    socket.on('chatRead', handleUnreadUpdate);
    socket.on('receiveGroupMessage', handleUnreadUpdate);
    socket.on('groupMessageRead', handleUnreadUpdate);
    socket.on('groupMessageReadByMember', handleUnreadUpdate);

    return () => {
      socket.off('newChat', handleUnreadUpdate);
      socket.off('receiveMessage', handleUnreadUpdate);
      socket.off('chatRead', handleUnreadUpdate);
      socket.off('receiveGroupMessage', handleUnreadUpdate);
      socket.off('groupMessageRead', handleUnreadUpdate);
      socket.off('groupMessageReadByMember', handleUnreadUpdate);
    };
  }, [socket, user.userId, dispatch]);

  // Set up interval to fetch unread count every 3 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      getUnreadCount();
    }, 3000); // 3000 milliseconds = 3 seconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [user.userId, dispatch]);

  // Handle notification click
  const notificationClickHandle = async () => {
    try {
      // Emit event to mark all messages as read
      socket.emit('markAsRead', { userId: user.userId, chatId: null }); // chatId: null indicates all chats

      // Optimistically set unread count to 0
      dispatch(setUnreadCount(0));

      // Dispatch actions to clear chat-related states
      dispatch(setSelectedCategoryId(null));
      dispatch(setChatSessionId(null));
      dispatch(setGroupChatSessionId(null));
      dispatch(setFriendId(null));
      dispatch(setFriendUserInfo({}));

      // Navigate to the chat page
      navigate('/chat');
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  };

  // Define NavBar components
  const NavLogo = (
    <>
      {!localStorage.getItem("first-time") ? (
        <Link to="/" onClick={handlePageClick}>
          <img 
            src='/logo/WM8.svg' 
            alt="Logo" 
            style={{
              width: "25px", 
              marginRight: "2vw",
              userDrag: 'none',
              WebkitUserDrag: 'none',
              userSelect: 'none',
              MozUserSelect: 'none',
              WebkitUserSelect: 'none',
              msUserSelect: 'none'
            }} 
          />
        </Link>
      ) : (
        <Tooltip title="Home">
          <Button
            onClick={() => {
              localStorage.removeItem('first-time');
              localStorage.removeItem('first-time-user');
              localStorage.setItem('first-time-home', true);
              localStorage.setItem('first-time-search', true);
              localStorage.setItem('first-time-m8', true);
              localStorage.setItem('first-time-d8', true);
              navigate(`/`);
              window.location.reload(true);
            }}
          >
            <img 
              src='/logo/WM8.svg' 
              alt="Logo" 
              style={{
                width: "25px", 
                marginRight: "2vw",
                userDrag: 'none',
                WebkitUserDrag: 'none',
                userSelect: 'none',
                MozUserSelect: 'none',
                WebkitUserSelect: 'none',
                msUserSelect: 'none'
              }} 
            />
          </Button>
        </Tooltip>
      )}
    </>
  );

  const NavLinks = (
    <>
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
        <IconButton
          size="large"
          aria-label="navigation menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          {!localStorage.getItem("first-time") ? <MenuIcon /> : (
            <Box display='flex' alignContent='center' mt={1} position={'fixed'} left={50} top={10}>
              <ArrowBackRoundedIcon fontSize='small'/> &nbsp; 
              <Typography fontSize={14} fontWeight={'bold'}>Click when done</Typography>
            </Box>
          )}
        </IconButton>
        <Menu
          className='header-menu-nav'
          anchorEl={anchorElNav}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          {pages.length && pages.map((page) => {
            if (page.role === "user" || (page.role === "admin" && user.role === "admin")) {
              const clickFunction = page.type === "button" ? page.onClick : handleCloseNavMenu;
              return (
                <MenuItem key={page.link} component={Link} to={page.link} onClick={clickFunction}>
                  <Typography textAlign="center">{page.text}</Typography>
                </MenuItem>
              );
            }
            return null;
          })}
        </Menu>
      </Box>

      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
        {!localStorage.getItem("first-time") ? pages.length && pages.map((page) => (
          (page.role === "user" || (page.role === "admin" && user.role === "admin")) && (
            <Button
              component={Link}
              to={page.link}
              key={page.text}
              onClick={handlePageClick}
              sx={{ 
                my: 2, 
                color: 'white', 
                display: 'block', 
                textAlign: 'center', 
                textTransform: 'none',
                justifyContent: 'center', 
                alignItems: 'center', 
                paddingLeft: 1, 
                paddingRight: 1, 
              }}
            >
              {page.text}
            </Button>
          )
        )) : (
          <Box display='flex' alignContent='center' mt={1} position={'fixed'} left={200} top={15}>
            <ArrowBackRoundedIcon fontSize='small'/> &nbsp; 
            <Typography fontSize={14} fontWeight={'bold'}>Click here when done</Typography>
          </Box>
        )}
      </Box>
    </>
  );

  const UserCount = (
    <Typography
      sx={{ 
        mr: 5, 
        color: 'white', 
        display: 'block', 
        textAlign: 'center', 
        textTransform: 'none', 
        fontWeight: 'normal', 
        fontSize: '15px', 
        MozUserSelect: '-moz-none',
        KhtmlUserSelect: 'none',
        WebkitUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
      }}
    >
      {userCount}
    </Typography>
  );

  const Notification = (
    <IconButton size="large" color="inherit" sx={{ mr: 1 }} onClick={notificationClickHandle}>
      <Badge badgeContent={unreadCount} color="error"> 
        <ForumIcon />
      </Badge>
    </IconButton> 
  );

  const UserAvatar = (
    <>
      <Tooltip title="Open settings" enterDelay={400}>
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>
          <Avatar 
            alt={user.name || "User"} 
            src={user.images && user.images.length > 0 ? user.images[0].mediaUrl : ""} 
            sx={{ height: "35px", width: "35px" }} 
          />
        </IconButton>
      </Tooltip>
      <Menu
        className='header-menu-profile'
        anchorEl={anchorElUser}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        sx={{ display: { xs: 'block', md: 'block' } }}
      >
        {options.length && options.map((option) => {
          if (option.role === "user" || (option.role === "admin" && user.role === "admin")) {
            const clickFunction = option.type === "button" ? option.onClick : handleCloseUserMenu;
            return (
              <MenuItem 
                key={option.text} 
                component={option.type === "link" ? Link : 'div'} 
                to={option.link} 
                onClick={clickFunction}
              >
                <Typography textAlign="center">
                  {option.icon}&nbsp; &nbsp;{option.text}
                </Typography>
              </MenuItem>
            );
          }
          return null;
        })}
      </Menu>
    </>
  );

  function SendUnreadCountToApp() {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ unreadCount: unreadCount }));
    }
  }

  SendUnreadCountToApp();
  return (
    <AppBar 
      className='header'
      position="sticky" 
      elevation={0} 
      sx={{ 
        zIndex: 1000, 
        bgcolor: 'var(--color-accent)',
        overscrollBehavior: 'contain'
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {NavLogo}
          {NavLinks}
          {UserCount}
          {Notification}
          {UserAvatar}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
