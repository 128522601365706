import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import settingService from 'api/services/settingService';

import { updateSettings } from 'app/slices/setting.slice';

import { Container, Tab, Tabs, Typography, Box, Button, Divider, RadioGroup, Radio, FormControl, FormControlLabel } from '@mui/material';
import SaveSettingsButton from './saveButton/SaveSettingsButton';

import { delay } from 'scripts/delay';

export default function NotificationSettings() {
    const dispatch = useDispatch();

    /* Email Notification toggle */
    const notiFreq = useSelector(state => state.settings.emailNotiFreq)
    const [emailNotiOption, setEmailNotiOption] = useState(notiFreq);
    // Email Notification options styles
    const radioStyle = {
        opacity: 0.2,
        color: 'var(--color-accent)',
        '&.Mui-checked': {
            color: 'var(--color-accent)',
            opacity: 1,
        }
    }
    const optionStyle = {
        opacity: 1,
        mr: 4
    }
    // Email Notification options
    const emailNotiOptions = [
        {label: "Off", value: "off"},
        {label: "Hourly", value: "hourly"},
        {label: "Daily", value: "daily"},  
        {label: "Weekly", value: "weekly"},  
        {label: "Monthly", value: "monthly"},  
    ]
    // Email Notification option handler
    const handleEmailNotiChange = (event) => {
        setEmailNotiOption(event.target.value);
    };
    // Main element
    const EmailNotiToggle = (<>
        <Typography variant='h6' fontWeight={'bold'} mt={3}>Email Notification: Unread Message </Typography>
        <Typography fontWeight={'normal'} sx={{opacity: 0.5}}>Change how frequent you receive notification emails regarding unread messages from M8s.</Typography>
        <FormControl sx={{ml: 1.5, mt: 2}}>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={emailNotiOption}
                onChange={handleEmailNotiChange}
            >
                {emailNotiOptions.length > 0 && emailNotiOptions.map((option, index) => 
                    <FormControlLabel key={index} value={option.value} control={<Radio sx={radioStyle} />} sx={optionStyle} label={option.label} />
                )}
            </RadioGroup>
        </FormControl>
    </>)

    /* Function for Save Button */
    const handleSave = () => {
        settingService.updateUserSetting({ emailNotiFreq: emailNotiOption }).then(async (res) => {
            dispatch(updateSettings(res));
        })
        
    }

    return (<>
        {EmailNotiToggle}
        
        {/* Save Button */}
        <SaveSettingsButton handleSave={handleSave}/>
    </>)
}