import axios from "../axios";

const createGroupChat = async (body) => {
    try {
        const response = await axios.post(`/group-chat/createGroupChat`, body);
        return response;
    } catch (error) {
        // Handle or throw error
        throw error;
    }
}

const getGroupChats = async () => {
    try {
        const response = await axios.get(`/group-chat/getGroupChats`);
        return response;
    } catch (error) {
        throw error;
    }
}

const getGroupChatMessages = async (groupChatId) => {
    try {
      const response = await axios.get(`/group-chat/getGroupChatMessages/${groupChatId}`);
      return response;
    } catch (error) {
      // Handle or throw error
      throw error;
    }
  };

const getGroupChatDetail = async (body) => {
    try {
        const response = await axios.post(`/group-chat/getGroupChatDetail/`, body);
        return response;
    } catch (error) {
        throw error;
    }
}

const getGroupChatMembers = async (body) => {
    try {
        const response = await axios.post(`/group-chat/getGroupChatMembers/`, body);
        return response;
    } catch (error) {
        throw error;
    }
}

const getGroupChatUserAssociations = async () => {
    try {
        const response = await axios.get(`/group-chat/getGroupChatUserAssociations/`);
        return response;
    } catch (error) {
        throw error;
    }
}

const addUserToGroupChat = async (body) => {
    try {
        const response = await axios.post(`/group-chat/addUserToGroupChat/`, body);
        return response;
    } catch (error) {
        throw error;
    }
}

const acceptUserToGroupChat = async (body) => {
    try {
        const response = await axios.post(`/group-chat/acceptUserToGroupChat/`, body);
        return response.data;
    } catch (error) {
        throw error;
    }
}

const removeUserFromGroupChat = async (body) => {
    try {
        const response = await axios.post(`/group-chat/removeUserFromGroupChat/`, body);
        return response;
    } catch (error) {
        throw error;
    }
}

const getUnreadMessageCount = async (body) => {
    try {
      const response = await axios.post(`/group-chat/getUnreadMessageCount`, body);
      return response;
    } catch (error) {
      // Handle or throw error
      throw error;
    }
  };

const getAllChats = async (userId) => {
    try {
        const response = await axios.get(`/chat/getAllChats/${userId}`);
        return response;
    } catch (error) {
        throw error;
    }
} 



const groupChatService = {
    createGroupChat,
    getAllChats,
    getGroupChats,
    getGroupChatMessages,
    getGroupChatDetail,
    getGroupChatMembers,
    getGroupChatUserAssociations,
    getUnreadMessageCount,

    addUserToGroupChat,
    acceptUserToGroupChat,
    removeUserFromGroupChat
};

export default groupChatService;