import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    // General

    // Account

    // Notification
    emailNotiFreq: '',
}

const settingSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
      updateSettings: (state, action) => {
        const { emailNotiFreq } = action.payload;

        // General

        // Account

        // Notification
        state.emailNotiFreq = emailNotiFreq ? emailNotiFreq : state.emailNotiFreq;
      
      
      }
    },
  });

  export const { updateSettings } = settingSlice.actions;
  export default settingSlice.reducer;