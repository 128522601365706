import React, { useState } from 'react';
import {
  Container,
  Typography,
  Button,
  Box,
  Tab,
  Tabs,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';

import {
  BasicsSearchForm,
  CareerSearchForm,
  PhysicalSearchForm,
  PersonalitySearchForm,
  TheTeaSearchForm,
} from '../forms/createFormComponent';
import userService from '../../api/services/userService';

import { isMobile } from '../../scripts/mobileCheck';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: grey[900], // Style for active tab text
          },
        },
      },
    },
  },
});

const Search = (props) => {
  const { setResults } = props;

  const [searchCriteria, setSearchCriteria] = useState({
    prefer: {},
    require: {},
    exclude: {},
  });

  // State to track the current main tab (Preference, Requires, Exclude)
  const [mainTabValue, setMainTabValue] = useState(0);
  const mainCategories = ['Prefer', 'Require', 'Exclude'];

  // State to track the current sub-tab (Basics, Career, etc.)
  const [tabValue, setTabValue] = useState(0);

  const formsConfig = [
    { title: 'Basics', component: BasicsSearchForm, dataKey: 'basics' },
    { title: 'Career', component: CareerSearchForm, dataKey: 'career' },
    { title: 'Physical', component: PhysicalSearchForm, dataKey: 'physical' },
    { title: 'Personality', component: PersonalitySearchForm, dataKey: 'personality' },
    { title: 'The Tea', component: TheTeaSearchForm, dataKey: 'theTea' },
  ];

  const updateSearchCriteria = (category, section, data) => {
    console.log(`Updating search criteria for category: ${category}, section: ${section}`);
    console.log('Data received:', data);
  
    setSearchCriteria((prev) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [section]: data,
      },
    }));
  };
  

  const handleSubmit = () => {
    userService
      .searchUserInfos(searchCriteria)
      .then((res) => {
        setResults(res);
        console.log('Search criteria:', searchCriteria);
        console.log('Results:', res);
      })
      
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
    setTabValue(0); // Reset sub-tab when main tab changes
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <Box
        className="search"
        sx={
          isMobile()
            ? {
                // Mobile layout
                width: '100%',
                border: '0px solid var(--color-accent)',
              }
            : {
                // PC layout
                width: '450px',
                height: '100%',
                overflowY: 'scroll',
                border: '2px solid var(--color-accent)',
                borderRadius: 'var(--border-radius-main)',
              }
        }
      >
        <Box mx={2} my={2} mb={5}>
          {/* Main Category Tabs */}
          <Tabs
            value={mainTabValue}
            onChange={handleMainTabChange}
            variant="fullWidth"
            textColor="primary"
            indicatorColor="primary"
            sx={{
              '.MuiTab-textColorPrimary': { color: grey[900] },
              '.MuiTabs-indicator': { backgroundColor: grey[900] },
            }}
          >
            {mainCategories.map((category, index) => (
              <Tab label={category} key={index} />
            ))}
          </Tabs>

          {/* Render forms under the selected main category */}
          {mainCategories.map((category, mainIndex) => (
            <div
              role="tabpanel"
              hidden={mainTabValue !== mainIndex}
              id={`main-tabpanel-${mainIndex}`}
              aria-labelledby={`main-tab-${mainIndex}`}
              key={mainIndex}
            >
              {mainTabValue === mainIndex && (
                <Box>
                  {/* Sub-tabs for forms */}
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto" // 'auto' will only show scroll buttons when needed
                    allowScrollButtonsMobile
                    textColor="primary"
                    indicatorColor="primary"
                    sx={{
                      '.MuiTab-textColorPrimary': { color: grey[900] },
                      '.MuiTabs-indicator': { backgroundColor: grey[900] },
                    }}
                  >
                    {formsConfig.map((form, index) => (
                      <Tab label={form.title} sx={{ textTransform: 'none' }} key={index} />
                    ))}
                  </Tabs>

                  {formsConfig.map((form, index) => (
                    <div
                      role="tabpanel"
                      hidden={tabValue !== index}
                      id={`tabpanel-${index}`}
                      aria-labelledby={`tab-${index}`}
                      key={index}
                    >
                      {tabValue === index && (
                        <Box p={3}>
                          <form.component
                            initialValues={
                              searchCriteria[mainCategories[mainTabValue].toLowerCase()] &&
                              searchCriteria[mainCategories[mainTabValue].toLowerCase()][form.dataKey]
                            }
                            onSubmit={(data) =>
                              updateSearchCriteria(
                                mainCategories[mainTabValue].toLowerCase(),
                                form.dataKey,
                                data
                              )
                            }
                            onValuesChange={(updatedValues) => {
                              updateSearchCriteria(
                                mainCategories[mainTabValue].toLowerCase(),
                                form.dataKey,
                                updatedValues
                              );
                            }}
                            isEditMode={false}
                            margin={'dense'}
                          />
                        </Box>
                      )}
                    </div>
                  ))}
                </Box>
              )}
            </div>
          ))}

          <Box display="flex" justifyContent="center" mt={2}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                backgroundColor: 'var(--color-accent)',
                borderRadius: '9999px',
                textTransform: 'none',
                transition: '0.2s',
                color: 'var(--color-text-invert)',
                opacity: 1,
                '&:hover': {
                  backgroundColor: 'var(--color-accent)',
                  color: 'var(--color-text-invert)',
                  opacity: 0.7,
                },
              }}
            >
              Search
            </Button>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};



export default Search;
